.altai-theme-admin .altaiplatform h1 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 0.1em;
}
.altai-theme-admin .altaiplatform h2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
.altai-theme-admin .altaiplatform h3 {
  font-size: 12px;
  font-weight: 200;
  letter-spacing: 0.1em;
}
